import React from 'react';
import Assign from './Assign/Assign';
import Profile from './Profile/Profile';

const AssignUsers = () => {
  return (
    <div>
      <Profile />
      <Assign />
    </div>
  );
};

export default AssignUsers;
