import React from 'react';
import Profile from './Profile/Profile';
import ViewDocument from './ViewDocument/ViewDocument';

const View = () => {
  return (
    <div>
      <Profile />
      <ViewDocument />
    </div>
  );
};

export default View;