import React, { useState } from 'react';
import { Link, navigate } from '@reach/router';
import { auth, signInWithGoogle } from '../../firebase/firebase';
import {
  Box,
  Button,
  Toast,
  Container,
  Text,
  TextField,
  Heading,
} from 'gestalt';
import 'gestalt/dist/gestalt.css';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    auth.signInWithEmailAndPassword(email, password).catch(error => {
      setError("Por favor, verifique se o email e a senha estão corretos!");
      console.error("Error signing in with password and email", error);
    });
  };

  return (
    <div>
      <Box padding={3}>
        <Container>
          <Box padding={2}>
            <Button onClick={signInWithGoogle} text="Entrar com Google" color="red" inline />
          </Box>
          <Box padding={2}>
            <Text><br></br><b>Cadastrar novo cliente</b></Text>
          </Box>
          <Box padding={2}>
            <Link to="signUp" className="text-blue-500 hover:text-blue-600">
              <b>Cadastrar aqui</b>
            </Link>
          </Box>
    
        </Container>
      </Box>
    </div>
  );
};
export default SignIn;
